import React, { useEffect, useState } from "react";
import { Dropdown, Table } from "react-bootstrap";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { shallowEqual, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as actions from "../_redux/Actions";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import './residents.scss';
import AccessModal from "../../../../_metronic/layout/components/AccessModal";
import OffCanvas from "../../../../_metronic/layout/components/OffCanvas";

const accesses = [
  {
    type: 'Resident',
    user: 'Odetola Azeez',
    address: '41 Akinwunmi street, Mende',
    datetime: '12:22 PM',
    status: true,
  },
  {
    type: 'Resident',
    user: 'Odetola Azeez',
    address: '41 Akinwunmi street, Mende',
    datetime: '12:22 PM',
    status: false,
  },
  {
    type: 'Resident',
    user: 'Odetola Azeez',
    address: '41 Akinwunmi street, Mende',
    datetime: '12:22 PM',
    status: true,
  },
  {
    type: 'Resident',
    user: 'Odetola Azeez',
    address: '41 Akinwunmi street, Mende',
    datetime: '12:22 PM',
    status: false,
  },
  {
    type: 'Resident',
    user: 'Odetola Azeez',
    address: '41 Akinwunmi street, Mende',
    datetime: '12:22 PM',
    status: true,
  },
]

export function Residents(props) {

  const dispatch = useDispatch();
  const [key, setKey] = useState("active");
  const [detailsOffCanvas, setDetailsOffCanvas] = useState(false);
  const [revokeAccessModal, setRevokeAccessModal] = useState(false);
  const [deleteResidentModal, setDeleteResidentModal] = useState(false)
  const [deleteResidentContinueModal, setDeleteResidentContinueModal] = useState(false);
  const [activeTab, setActiveTab] = useState('info');
  const [addSingleResidentModal, setAddSingleResidentModal] = useState(false);
  const [uploadResidentsModal, setUploadResidentsModal] = useState(false);
  const [formFields, setFormFields] = useState({});
  const [residents, setResidents] = useState([
    { name: 'Tosin Israel', address: '2 James Harvey Avenue...Cluster C, 1004, Victoria Island, Lagos state', phone: '09088748321', accessStatus: 'active' },
    { name: 'Dozie Mide', address: '2 James Harvey Avenue...Cluster C, 1004, Victoria Island, Lagos state', phone: '09088748321', accessStatus: 'disabled' },
    { name: 'Femi Olu', address: '2 James Harvey Avenue...Cluster C, 1004, Victoria Island, Lagos state', phone: '09088748321', accessStatus: 'active' },
    { name: 'Nwoga Akomolafe', address: '2 James Harvey Avenue...Cluster C, 1004, Victoria Island, Lagos state', phone: '09088748321', accessStatus: 'active' },
    { name: 'Adetiwa Tosin', address: '2 James Harvey Avenue...Cluster C, 1004, Victoria Island, Lagos state', phone: '09088748321', accessStatus: 'active' },
  ]);
  const [residentDetails, setResidentDetails] = useState(residents[0]);

  const openResidentDetails = (data) => {
    setResidentDetails(data);
    setDetailsOffCanvas(true);
  }

  const { pending_danger } = useSelector(
    (state) => ({
      org: state.auth.organization,
      active_danger: state.alert.active_danger,
      pending_danger: state.alert.pending_danger,
    }),
    shallowEqual
  );

  const maxString = (str, lgt) => {
    let res = str;
    if (str.length <= lgt) {
      return res
    }
    else {
      const strArr = str.split('');
      strArr.length = lgt;
      res = `${strArr.join('')}...`;
    }
    return res;
  }

  const handleChange = (field, value) => {
    const oldform = { ...formFields };
    oldform[field] = value;
    setFormFields(oldform);
  }

  return (
    <>
      <div className="residents-container overflow-hidden">
        <div className="d-flex justify-content-between align-items-center w-100 resident-overview-data">
          <h3 className=""> {residents?.length} Residents</h3>
          <div className="menu-items">
            <button className="button button-secondary">All Residents</button>
            <button className="button button-secondary">Filter</button>
            <Dropdown role="menu">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Add resident
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setAddSingleResidentModal(true)}>Add a single resident</Dropdown.Item>
                <Dropdown.Item onClick={() => setUploadResidentsModal(true)}>Bulk Upload</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="residents-table">
          <Table responsive hover>
            <thead>
              <tr>
                <th>Resident's name</th>
                <th>Address</th>
                <th>Phone</th>
                <th>Access code</th>
              </tr>
            </thead>
            <tbody>
              {residents?.map((resident, index) => (
                <tr onClick={() => openResidentDetails(resident)}>
                  <td>{resident.name}</td>
                  <td>{maxString(resident.address, 44)}</td>
                  <td>{resident.phone}</td>
                  <td className="status">
                    <span className={`${resident.accessStatus == 'active' ? 'active' : 'disabled'}`}>
                      {resident.accessStatus == 'active' ? 'Active' : 'Disabled'}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <OffCanvas show={detailsOffCanvas} handleClose={() => setDetailsOffCanvas(false)}>
        <div className="side-panel-body-header" onClick={() => setDetailsOffCanvas(false)}>
          <img src={toAbsoluteUrl('/media/misc/arrow-back.svg')} className="item-icon" />
          <span>Back to Residents</span>
        </div>
        <div className="tabs">
          <button className={`${activeTab == 'info' && 'active'} tab`} onClick={() => setActiveTab('info')}>Info</button>
          <button className={`${activeTab == 'visitors' && 'active'} tab`} onClick={() => setActiveTab('visitors')}>Visitors</button>
        </div>
        {activeTab == 'info' &&
          <div className="infoo">
            <div className="user-info">
              <span className="name">{residentDetails?.name}</span>
              <span className="access">
                <span>ESTATE ACCESS</span>
                <span className="status">
                  <span className={`${residentDetails.accessStatus == 'active' ? 'active' : 'disabled'}`}>
                    {residentDetails.accessStatus == 'active' ? 'Active' : 'Disabled'}
                  </span>
                </span>
              </span>
              <span className="address">{residentDetails?.address}</span>
            </div>
            <div className="others">
              <span className="sec-title">Other information</span>
              <span className="other d-flex align-items-center">
                <img src={toAbsoluteUrl('/media/misc/phone.svg')} className="item-icon" />
                <span>{residentDetails?.phone}</span>
              </span>
              <span className="other d-flex align-items-center">
                <img src={toAbsoluteUrl('/media/misc/email.svg')} className="item-icon" />
                <span>{residentDetails?.email || 'support@estate.com'}</span>
              </span>
            </div>
            <div className="actions">
              <button className="a">Edit resident info</button>
              <button className="b" onClick={() => [setDetailsOffCanvas(false), setRevokeAccessModal(true)]}>Revoke access</button>
            </div>
            <button className="delete" onClick={() => [setDetailsOffCanvas(false), setDeleteResidentModal(true)]}>Delete resident from estate</button>
          </div>}
        {activeTab == 'visitors' &&
          <div className="visitorrs">
            <div className="confirmed-passess">
              {accesses.map((access, index) => (
                <div className="d-flex align-items-start pass-details">
                  <img src={toAbsoluteUrl('/media/misc/item-icon.png')} className="item-icon" />
                  <div className="details">
                    <span className="pass-type">{access.type}</span>
                    <span className="user">{access.user}</span>
                    <div className="d-flex align-items-center address">
                      <img src={toAbsoluteUrl('/media/misc/location.png')} className="data-icon" />
                      <span className="addr">{access.address}</span>
                    </div>
                    <div className="d-flex align-items-center time-access">
                      <div className="d-flex align-items-center">
                        <img src={toAbsoluteUrl('/media/misc/datetime.png')} className="data-icon" />
                        <span className="time">{access.datetime}</span>
                      </div>
                      <span className={`status ${access.status ? 'granted' : 'declined'}`}>
                        {access.status ? 'Access granted' : 'Access denied - Expired'}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        }
      </OffCanvas>
      <AccessModal
        show={revokeAccessModal}
        title="Revoke estate access"
        continueText="Revoke Access"
        handleClose={() => setRevokeAccessModal(false)}
        handleContinue={() => setRevokeAccessModal(false)}
      >
        <p className="warning">You're about to revoke estate access for this resident;</p>
        <div className="user-info">
          <span className="name">{residentDetails?.name}</span>
          <span className="address">{residentDetails?.address}</span>
          <span>{residentDetails?.phone}</span>
          <span>{residentDetails?.email || 'support@estate.com'}</span>
        </div>
      </AccessModal>

      <AccessModal
        show={deleteResidentModal}
        title="Delete resident"
        handleClose={() => setDeleteResidentModal(false)}
        handleContinue={() => [setDeleteResidentModal(false), setDeleteResidentContinueModal(true)]}
      >
        <p className="warning">You're about to delete this resident from the estate. This action is not reversible.</p>
        <p className="warning">Do you want to continue?</p>
        <div className="user-info">
          <span className="name">{residentDetails?.name}</span>
          <span className="address">{residentDetails?.address}</span>
          <span>{residentDetails?.phone}</span>
          <span>{residentDetails?.email || 'support@estate.com'}</span>
        </div>
      </AccessModal>

      <AccessModal
        show={deleteResidentContinueModal}
        title="Delete resident"
        continueText="Delete resident"
        handleClose={() => setDeleteResidentContinueModal(false)}
        handleContinue={() => setDeleteResidentContinueModal(false)}
      >
        <p className="warning">You're about to delete this resident from the estate. This action is not reversible.</p>
        <p className="warning">Do you want to continue?</p>
        <div className="user-info mb-16">
          <input type="text" className="modal-input" placeholder="Enter comment" />
        </div>
      </AccessModal>

      <AccessModal
        show={addSingleResidentModal}
        title="Add a resident"
        continueText="Add a resident"
        continueType="primary"
        cancelType="plain"
        handleClose={() => setAddSingleResidentModal(false)}
        handleContinue={() => setAddSingleResidentModal(false)}
      >
        <div className="form-group">
          <input type="text" className="modal-input" placeholder="First name"
            value={formFields.firstName} onChange={(e) => handleChange('firstname', e.target.value)} />
          <input type="text" className="modal-input" placeholder="Last name"
            value={formFields.lastName} onChange={(e) => handleChange('lastname', e.target.value)} />
        </div>

        <div className="form-group">
          <input className="modal-input" placeholder="Email" type="email"
            value={formFields.email} onChange={(e) => handleChange('email', e.target.value)} />
          <input className="modal-input" placeholder="Phone" type="phone"
            value={formFields.phone} onChange={(e) => handleChange('phone', e.target.value)} />
        </div>

        <input type="text" className="modal-input" placeholder="Address"
          value={formFields.address} onChange={(e) => handleChange('address', e.target.value)} />
      </AccessModal>

      <AccessModal
        show={uploadResidentsModal}
        title="Upload residents"
        continueText="Add a resident"
        continueType="primary"
        cancelType="plain"
        handleClose={() => setUploadResidentsModal(false)}
        handleContinue={() => setUploadResidentsModal(false)}
      >
        <div className="requirements">
          <h5>Requirements</h5>
          <ul>
            <li>The file must be CSV</li>
            <li>CSV file should contain the following columns  First name, Last name, Email, phone number, and House address</li>
            <li>The order of the columns should be same as the order in which they are listed above with the first row as headers.</li>
          </ul>
          <div className="upload">
            <label htmlFor="upload-file">
              <img src={toAbsoluteUrl('/media/misc/file-upload.svg')} className="item-icon" />
              Download a sample bulk CSV file
            </label>
            <input type="file" name="upload-file" id="upload-file" />
          </div>
        </div>
      </AccessModal>
    </>
  );
}
