import React, { useEffect, useState } from "react";
import { Dropdown, Table } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as actions from "../_redux/Actions";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import AccessModal from "../../../../_metronic/layout/components/AccessModal";
import './settings.scss';


const users = [
  { agentName: 'Tosin Israel', email: 'email@email.com', role: 'Admin', lastLogin: 'Aug 13 2020 2:21 PM' },
  { agentName: 'Tosin Israel', email: 'email@email.com', role: 'Admin', lastLogin: 'Aug 13 2020 2:21 PM' },
  { agentName: 'Tosin Israel', email: 'email@email.com', role: 'Admin', lastLogin: 'Aug 13 2020 2:21 PM' },
  { agentName: 'Tosin Israel', email: 'email@email.com', role: 'Admin', lastLogin: 'Aug 13 2020 2:21 PM' },
  { agentName: 'Tosin Israel', email: 'email@email.com', role: 'Admin', lastLogin: 'Aug 13 2020 2:21 PM' },
]
export function Residents(props) {

  const dispatch = useDispatch();
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [activeTab, setActiveTab] = useState('general');
  const [formFields, setFormFields] = useState({});
  const [addCardInitModal, setAddCardInitModal] = useState(false);
  const [addCardFormModal, setAddCardFormModal] = useState(false);
  const [removeUserModal, setRemoveUserModal] = useState(false);
  const [changeRoleModal, setChangeRoleModal] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);


  const { pending_danger } = useSelector(
    (state) => ({
      org: state.auth.organization,
      active_danger: state.alert.active_danger,
      pending_danger: state.alert.pending_danger,
    }),
    shallowEqual
  );

  const maxString = (str, lgt) => {
    let res = str;
    if (str.length <= lgt) {
      return res
    }
    else {
      const strArr = str.split('');
      strArr.length = lgt;
      res = `${strArr.join('')}...`;
    }
    return res;
  }

  const handleChange = (field, value) => {
    const oldform = { ...formFields };
    oldform[field] = value;
    setFormFields(oldform);
  }

  return (
    <>
      <div className="settings-container overflow-hidden">
        <div className="tabs">
          <button className={`${activeTab == 'general' && 'active'} tab`} onClick={() => setActiveTab('general')}>General</button>
          <button className={`${activeTab == 'estate' && 'active'} tab`} onClick={() => setActiveTab('estate')}>Estate</button>
          <button className={`${activeTab == 'payment' && 'active'} tab`} onClick={() => setActiveTab('payment')}>Payment</button>
          <button className={`${activeTab == 'users' && 'active'} tab`} onClick={() => setActiveTab('users')}>Users</button>
        </div>
        {activeTab === 'general'
          ? <div className="settings-form">
            <h6>Personal Information</h6>
            <div className="form-content">
              <div className="fields">
                <div className="form-group">
                  <input className="modal-input" placeholder="First name" type="text"
                    value={formFields.email} onChange={(e) => handleChange('firstname', e.target.value)} />
                  <input className="modal-input" placeholder="Last name" type="text"
                    value={formFields.phone} onChange={(e) => handleChange('lastname', e.target.value)} />
                </div>

                <div className="form-group">
                  <input className="modal-input" placeholder="Email" type="email"
                    value={formFields.email} onChange={(e) => handleChange('email', e.target.value)} />
                  <input className="modal-input" placeholder="Phone" type="phone"
                    value={formFields.phone} onChange={(e) => handleChange('phone', e.target.value)} />
                </div>
                <div className="form-group">
                  <input type="text" className="modal-input" placeholder="State"
                    value={formFields.state} onChange={(e) => handleChange('state', e.target.value)} />
                </div>
              </div>
              <div className="image-upload">
                <img src={toAbsoluteUrl('/media/misc/upload-picture.svg')} className="item-icon" />
                <button>Upload</button>
              </div>
            </div>
            <div className="submit-changes">
              <button className="plain" onClick={() => setChangePasswordModal(true)}>Change password</button>
              <button className="primary" onClick={() => console.log('kds')}>Save</button>
            </div>
          </div>
          : null}
        {activeTab === 'estate'
          ? <div className="settings-form">
            <h6>Estate infromation</h6>
            <div className="form-content no-border">
              <div className="fields">
                <div className="form-group">
                  <input className="modal-input" placeholder="Estate name" type="text"
                    value={formFields.email} onChange={(e) => handleChange('estateName', e.target.value)} />
                  <input className="modal-input" placeholder="Email" type="email"
                    value={formFields.phone} onChange={(e) => handleChange('email', e.target.value)} />
                </div>

                <div className="form-group">
                  <input className="modal-input" placeholder="State" type="text"
                    value={formFields.email} onChange={(e) => handleChange('state', e.target.value)} />
                  <input className="modal-input" placeholder="Address" type="text"
                    value={formFields.phone} onChange={(e) => handleChange('address', e.target.value)} />
                </div>
              </div>
              <div className="image-upload">
                <img src={toAbsoluteUrl('/media/misc/upload-picture.svg')} className="item-icon" />
                <button>Upload</button>
              </div>
            </div>
            <h6>Contact Person</h6>
            <div className="form-content">
              <div className="fields">
                <div className="form-group">
                  <input className="modal-input" placeholder="Full name" type="text"
                    value={formFields.email} onChange={(e) => handleChange('fullname', e.target.value)} />
                  <input className="modal-input" placeholder="Phone" type="text"
                    value={formFields.phone} onChange={(e) => handleChange('phone', e.target.value)} />
                </div>

                <div className="form-group">
                  <input className="modal-input" placeholder="Email" type="email"
                    value={formFields.email} onChange={(e) => handleChange('email', e.target.value)} />
                </div>
              </div>
            </div>

            <div className="submit-changes">
              <button className="primary" onClick={() => console.log('kds')}>Save</button>
            </div>
          </div>
          : null}

        {activeTab === 'payment'
          ? <div className="cards">
            <div className="d-flex justify-content-between align-items-center mb-8">
              <h6>Payment infromation</h6>
              <button onClick={() => setAddCardInitModal(true)}>
                Add card
              </button>
            </div>
            <div className="cards-content">
              {Array(3).fill('x').map((_, index) => (
                <div className="debit-card" key={index}>
                  <span className="debit-card-number">**** 0293</span>
                  {index % 2 ? <span className="debit-card-default">Default</span> : null}
                  <div className="debit-card-base">
                    <div className="expiry">
                      <span>Expires</span>
                      <span className="exp-date">Aug 2024</span>
                    </div>
                    <img src={toAbsoluteUrl('/media/misc/visa.svg')} className="card-type" />
                  </div>
                </div>
              ))}
            </div>
          </div>
          : null
        }

        {activeTab === 'users'
          ? <div className="users">
            <div className="d-flex justify-content-between align-items-center mb-8">
              <h6>5 users</h6>
              <button onClick={() => setAddUserModal(true)}>
                Add user
              </button>
            </div>
            <div className="settings-table">
              <Table responsive hover>
                <thead>
                  <tr>
                    <th>Agent's name</th>
                    <th>email</th>
                    <th>Role</th>
                    <th>Last login</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {users?.map((user, index) => (
                    <tr key={index}>
                      <td>{user.agentName}</td>
                      <td>{user.email}</td>
                      <td>{user.role}</td>
                      <td>{user.lastLogin}</td>
                      <td className="row-action">
                        <button className="plain" onClick={() => setChangeRoleModal(true)}>Change role</button>
                        <button className="remove" onClick={() => setRemoveUserModal(true)}>Remove</button>
                      </td>

                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
          : null
        }
      </div >
      <AccessModal
        show={changePasswordModal}
        title="Change password"
        continueText="Update"
        continueType="primary"
        cancelType="plain"
        handleClose={() => setChangePasswordModal(false)}
        handleContinue={() => setChangePasswordModal(false)}
      >
        <div className="field">
          <input type="password" className="modal-input" placeholder="Current Password"
            value={formFields.currPassword} onChange={(e) => handleChange('currPassword', e.target.value)} />
          <img src={toAbsoluteUrl('/media/misc/eye-on.svg')} className="item-icon" />
        </div>
        <div className="field">
          <input type="password" className="modal-input" placeholder="New Password"
            value={formFields.newPassword} onChange={(e) => handleChange('newPassword', e.target.value)} />
          <img src={toAbsoluteUrl('/media/misc/eye-on.svg')} className="item-icon" />
        </div>
        <div className="field">
          <input type="password" className="modal-input" placeholder="Re-enter new password"
            value={formFields.newPassword2} onChange={(e) => handleChange('newPassword2', e.target.value)} />
          <img src={toAbsoluteUrl('/media/misc/eye-on.svg')} className="item-icon" />
        </div>
      </AccessModal>

      <AccessModal
        show={addCardInitModal}
        title="Add a new card"
        continueText="Continue"
        continueType="primary"
        cancelType="plain"
        handleClose={() => setAddCardInitModal(false)}
        handleContinue={() => [setAddCardInitModal(false), setAddCardFormModal(true)]}
      >
        <p className="instruction">To add and verify your bank account, NGN 100.00 will be charged from your bank account.</p>
      </AccessModal>

      <AccessModal
        show={addCardFormModal}
        title="Add a new card"
        continueText="Continue"
        continueType="primary"
        cancelType="plain"
        handleClose={() => setAddCardFormModal(false)}
        handleContinue={() => setAddCardFormModal(false)}
      >
        <div className="field">
          <input type="text" className="modal-input" placeholder="Card number"
            value={formFields.cardNumber} onChange={(e) => handleChange('cardNumber', e.target.value)} />
        </div>
        <div className="field">
          <input type="number" className="modal-input" placeholder="Expiry"
            value={formFields.expiry} onChange={(e) => handleChange('expiry', e.target.value)} />
        </div>
        <div className="field">
          <input type="text" className="modal-input" placeholder="CVV"
            value={formFields.cvv} onChange={(e) => handleChange('cvv', e.target.value)} />
        </div>
      </AccessModal>

      <AccessModal
        show={removeUserModal}
        title="Remove user"
        continueText="Remove"
        continueType="danger"
        cancelType="plain"
        handleClose={() => setRemoveUserModal(false)}
        handleContinue={() => setRemoveUserModal(false)}
      >
        <p className="instruction">James Zagadat will no longer be able to access your dashboard. Do you want to Proceed?</p>
      </AccessModal>

      <AccessModal
        show={changeRoleModal}
        title="Change role"
        continueText="Update"
        continueType="primary"
        cancelType="plain"
        handleClose={() => setChangeRoleModal(false)}
        handleContinue={() => setChangeRoleModal(false)}
        contentStyle="manage-role"
      >
        <div className="change-role">
          <form className="change-role-form">
            <select>
              <option>Administrator</option>
              <option>Role D1</option>
              <option>Role D2</option>
              <option>Role D3</option>
            </select>
          </form>
          <ul>
            <li>
              <span className="key">Administrator:</span>
              <span className="description">Role description... pretium lacinia ac porta odio, quis facilisis lacus. Nunc porta, eros quis faucibus.</span>
            </li>
            <li>
              <span className="key">Role D1:</span>
              <span className="description">Role description... pretium lacinia ac porta odio, quis facilisis lacus. Nunc porta, eros quis faucibus.</span>
            </li>
            <li>
              <span className="key">Role D2:</span>
              <span className="description">Role description... pretium lacinia ac porta odio, quis facilisis lacus. Nunc porta, eros quis faucibus.</span>
            </li>
            <li>
              <span className="key">Role D3:</span>
              <span className="description">Role description... pretium lacinia ac porta odio, quis facilisis lacus. Nunc porta, eros quis faucibus.</span>
            </li>
          </ul>
        </div>
      </AccessModal>

      <AccessModal
        show={addUserModal}
        title="Add user"
        continueText="Add user"
        continueType="primary"
        cancelType="plain"
        handleClose={() => setAddUserModal(false)}
        handleContinue={() => setAddUserModal(false)}
        contentStyle="manage-role"
      >
        <div className="change-role">
          <form className="change-role-form">
            <input type="email" placeholder="User email" className="modal-input mb-8" />
            <select>
              <option>Administrator</option>
              <option>Role D1</option>
              <option>Role D2</option>
              <option>Role D3</option>
            </select>
          </form>
          <ul>
            <li>
              <span className="key">Administrator:</span>
              <span className="description">Role description... pretium lacinia ac porta odio, quis facilisis lacus. Nunc porta, eros quis faucibus.</span>
            </li>
            <li>
              <span className="key">Role D1:</span>
              <span className="description">Role description... pretium lacinia ac porta odio, quis facilisis lacus. Nunc porta, eros quis faucibus.</span>
            </li>
            <li>
              <span className="key">Role D2:</span>
              <span className="description">Role description... pretium lacinia ac porta odio, quis facilisis lacus. Nunc porta, eros quis faucibus.</span>
            </li>
            <li>
              <span className="key">Role D3:</span>
              <span className="description">Role description... pretium lacinia ac porta odio, quis facilisis lacus. Nunc porta, eros quis faucibus.</span>
            </li>
          </ul>
        </div>
      </AccessModal>
    </>
  );
}
