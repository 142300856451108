import * as requestFromServer from "./Crud";
import { toast } from "react-toastify";
import {DashboardSlice, callTypes} from "./Slice";
const {actions} = DashboardSlice;


export const fetchAllData = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDatas(type, queryParams)
    .then(response => {
      var res = response.data;
      // console.log("Server response is", response.data);
      if (type === 'dashboard_data') {
        dispatch(actions.dashboardData(res.data));
      } else if (type === 'users') {
        // console.log("userssss", res)
        dispatch(actions.accounts(res.data.data));
        dispatch(actions.accountsMeta(res.data.meta ? res.data.meta : null));
      } else if (type === 'estate_codes') {
        dispatch(actions.estateCodes(res.data));
      } else if (type === 'estate_logs') {
        dispatch(actions.estateLogs(res.data));
      } 
   })
    .catch(error => {
      var message = error.response.data.message;
      
      toast.error(message || message?.error || "Server error occured. Please try again!");
      error.clientMessage = "Can't find Datas";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      return null;
    });
};

export const fetchData = id => dispatch => {
  if (!id) {
    return dispatch(actions.DataFetched({ DataForEdit: undefined }));
  }

};

export const deleteData = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteData(id)
    .then(response => {
      dispatch(actions.DataDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete Data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

function isValidJson(value) {
  try {
    JSON.parse(value);
    return true;
  } catch (error) {
    return false;
  }
}


export const addData = (type, DataForCreation) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addNewData(type, DataForCreation)
    .then(response => {
      var res = response.data;
      // console.log(res);
      dispatch(actions.stopLoading());
      if (type === 'code') {
        toast.success("New access code successfully generated");
        dispatch(actions.codedAdded(res.data));
        return res;
      }

    })
    .catch(error => {
      var message = error.response.data.message;
      // var status_code = error.response.status;
      // console.log(error.response)
      toast.error(!isValidJson(message) ? message : message?.error || "Server error occured. Please try again!" );
      error.clientMessage = "Can't create Data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateData = Data => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateData(Data)
    .then(() => {
      dispatch(actions.DataUpdated({ Data }));
    })
    .catch(error => {
      error.clientMessage = "Can't update Data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateDatasStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForDatas(ids, status)
    .then(() => {
      dispatch(actions.DatasStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update Datas status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteDatas = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteDatas(ids)
    .then(() => {
      dispatch(actions.DatasDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete Datas";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
