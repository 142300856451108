/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
// import Icon from '@mui/material/Icon';

// import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
// import ThreeDRotation from '@mui/icons-material/ThreeDRotation';

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const returnAct = (url) => {
    return checkIsActive(location, url)
      ? true
      : false;
  };


  const links = [
    {
      name: 'Overview',
      link: '/dashboard'
    },
    {
      name: 'Residents',
      link: '/residents'
    },
    {
      name: 'Access Codes',
      link: '/access'
    },
    {
      name: 'Settings',
      link: '/settings'
    },
    {
      name: 'API Docs',
      link: '/docs'
    }
  ]


  function getRoute(name, active) {
    if (name === 'Overview') {
      if (active) {
        return "/media/nav/dashboard_active.svg";
      } else {
        return "/media/nav/dashboard.svg";
      }
    } else if (name === 'Residents') {
      if (active) {
        return "/media/nav/residents-active.svg";
      } else {
        return "/media/nav/residents.svg";
      }
    } else if (name === 'Access Codes') {
      if (active) {
        return "/media/nav/access-active.svg";
      } else {
        return "/media/nav/access.svg";
      }
    } else if (name === 'Settings') {
      if (active) {
        return "/media/nav/settings-active.svg";
      } else {
        return "/media/nav/settings.svg";
      }
    } else if (name === 'API Docs') {
      if (active) {
        return "/media/nav/docs.svg";
      } else {
        return "/media/nav/docs.svg";
      }
    }
    return "/media/nav/dashboard.svg";
  }

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {
          links.map((ele, i) => {
            return (
              <li key={i}
                className={`menu-item`}
                style={{
                  marginBottom: 16,
                  padding: '8px 10px',
                  marginTop: i === 0 ? 10 : 0,

                }}
              >
                <NavLink className="menu-link" style={{
                  backgroundColor: 'transparent'
                }} to={ele.link}>
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl(getRoute(ele.name, returnAct(ele.link)))} style={{ width: '24px', height: '24px', }} />
                  </span>
                  <span className="menu-text" style={{
                    color: returnAct(ele.link) ? '#5341C4' : '#393939',
                    fontWeight: returnAct(ele.link) ? 600 : 500,
                    fontSize: '16px',
                  }}>{ele.name}</span>
                </NavLink>
              </li>
            )
          })
        }
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
