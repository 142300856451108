import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { toAbsoluteUrl } from "../../_helpers";
import './extras.scss'

export default function AccessModal({ children, show, handleClose, handleContinue, title, cancelText, continueText, continueType, cancelType, contentStyle }) {
  return (
    <Modal show={show} onHide={handleClose}>
      <div className="header">
        <h4>{title}</h4>
        <img src={toAbsoluteUrl('/media/misc/modal-close.svg')} className="modal-icon" onClick={handleClose} />
      </div>
      <div className={`${contentStyle ? contentStyle : null} content`}>
        {children}
      </div>
      <div className="footer">
        <button className={`${cancelType || 'cancel'}`} onClick={handleClose}>{cancelText || 'Cancel'}</button>
        <button className={`${continueType || 'danger'}`} onClick={handleContinue}>{continueText || 'Continue'}</button>
      </div>
    </Modal>
  )
}
